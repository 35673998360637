<script>
    import TimeLineItemLoader from './TimeLineItemLoader.vue';

    export default {
        name: 'publish-post',
        props: {
            userTypeDir: {type: String, required: true},
            moduleId: {type: Number, required: true},
            userType: {type: Number, required: true},
            onlyShowOwnStudents: {type: Boolean, required: true},
            referenceId: {type: Number, required: true},
            classLevels: {type: Array, required: true}
        },
        data() {
            return {
                content: '',
                student: true,
                parent: true,
                teacher: true,
                systemManager: true,
                classLevel: "-1",
                canLeaveComment: true,
                addImagesSectionVisible: false,
                images: [],
                maxImages: 10,
                activeInput: 0,
                validFileTypes: ["image/jpeg", "image/png"],
                pending: false,
                isParticipantListLoaded: false,
                participantSelection: 'easy-selection',
                filterInput: '',
                delay: null
            }
        },
        methods: {
            sharePost() {
                if(this.content === '') {
                    alert('İçerik boş olamaz.');
                } else if(this.content.length > 1000) {
                    alert('Paylaşımınız 1000 karakteri geçemez.');
                } else {
                    const scope = this;
                    const userCanShareForAllUser = this.userType === 4 || (this.userType === 3 && this.onlyShowOwnStudents === 0);
                    let participation = {
                        student: [],
                        parent: [],
                        teacher: [],
                        systemManager: [],
                        all: []
                    };
                    participation.all[1] = 0;
                    participation.all[2] = 0;
                    participation.all[3] = 0;
                    participation.all[4] = 0;

                    if (this.participantSelection === 'detailed-selection') {
                        $.map($("#participantTreeList").fancytree("getTree").getSelectedNodes(), function(node) {
                            if (node.parent.key.startsWith('root_')) {
                                participation.all[parseInt(node.key)] = 1
                            } else if (!node.key.startsWith('exclude-')) {
                                if (node.parent.key === '3') {
                                    participation.teacher.push(node.key);
                                } else if (node.parent.parent.key === "1") {
                                    participation.student.push(node.key);
                                } else {
                                    participation.parent.push(node.key);
                                }
                            }
                        });

                        if (participation.all[1] && userCanShareForAllUser) {
                            participation.student = [];
                        }

                        if (participation.all[2] && userCanShareForAllUser) {
                            participation.parent = [];
                        }

                        if(participation.all[3]) {
                            participation.teacher = [];
                        }

                        if(participation.all[4]) {
                            participation.systemManager = [];
                        }
                    }

                    let vars = {
                        request: 'share-post',
                        moduleId: this.moduleId,
                        referenceId: this.referenceId,
                        content: this.content,
                        student: this.student,
                        parent: this.parent,
                        teacher: this.teacher,
                        systemManager: this.systemManager,
                        classLevel: this.classLevel,
                        canLeaveComment: this.canLeaveComment,
                        participantSelection: this.participantSelection,
                        participationStuAll: participation.all[1],
                        participationParAll: participation.all[2],
                        participationTeaAll: participation.all[3],
                        participationSysAll: participation.all[4],
                        participationStu: participation.student,
                        participationPar: participation.parent,
                        participationTea: participation.teacher,
                        participationSys: participation.systemManager
                    };

                    $.each(this.images, function (i, val) {
                        if (scope.$refs['imageInput-' + i][0].files.length === 1) {
                            vars['image-' + i] = scope.$refs['imageInput-' + i][0].files[0];
                        }
                    });

                    callAjax(vars, {
                        url: `obsapi/timeline/sharePost`,
                        context: this
                    },function (obj, scope) {
                        scope.setDefaultToPublishPostForm();
                        scope.pending = false;
                        scope.$emit('addLoaderToTimeLine', false);
                        scope.$emit('addPostToTimeLine', obj.data);
                    },function (scope) {
                        scope.pending = true;
                        scope.$emit('addLoaderToTimeLine', true);
                    },function (scope) {
                        scope.pending = false;
                        scope.$emit('addLoaderToTimeLine', false);
                    },function (scope) {
                        scope.pending = false;
                        scope.$emit('addLoaderToTimeLine', false);
                    });
                }
            },
            setPhotosSectionVisibility() {
                this.addImagesSectionVisible = true;
                this.openImageInput();
            },
            openImageInput() {
                if(this.maxImages > this.images.length) {
                    const input = this.$refs['imageInput-' + this.activeInput][0];
                    input.click();
                }
            },
            setDefaultToPublishPostForm() {
                this.content = '';
                this.student = true;
                this.parent = true;
                this.teacher = true;
                this.systemManager = true;
                this.classLevel = "-1";
                this.canLeaveComment = true;
                this.addImagesSectionVisible = false;
                this.images = [];
                this.activeInput = 0;
                this.isParticipantListLoaded = false;
                this.participantSelection = 'easy-selection';
                this.filterInput = '';

                $("#participantTreeList").fancytree();
                $("#participantTreeList").fancytree('destroy');

                for (let i = 0; i < this.maxImages; i++) {
                    this.$refs['imageInput-' + i][0].value = "";
                }
            },
            loadImage() {
                const input = this.$refs['imageInput-' + this.activeInput][0];
                const file = input.files[0];

                if (input.files && file && this.validFileTypes.indexOf(file.type) > -1 && file.size <= 3145728) {

                    const reader = new FileReader();
                    const scope = this;

                    reader.onload = function (e) {
                        scope.images.push({file: file, src: e.target.result});
                        scope.activeInput++;
                    };

                    reader.readAsDataURL(file);
                } else {
                    toastr.warning('Seçtiğiniz dosya 3MB\'ı aşmayan resim dosyası olmalı.');
                    this.$refs['imageInput-' + this.activeInput][0].value = "";
                }
            },
            getParticipantList() {
                if (!this.isParticipantListLoaded) {
                    callAjax(null,{
                        url: 'obsapi/timeline/getParticipantList',
                        method: 'GET',
                        context: this
                    },function (obj, scope) {
                        scope.isParticipantListLoaded = true;
                        $("#participantTreeList").fancytree({
                            extensions: ["filter"],
                            filter: {
                                counter: true,
                                mode: "hide",
                                nodata: true,
                                leavesOnly: false,
                                autoExpand: true,
                                autoApply: true
                            },
                            checkbox: true,
                            selectMode: 3,
                            source: obj.data
                        });
                    });
                }
            },
            filterParticipantTreeList() {
                let self = this;
                window.clearTimeout(this.delay);

                this.delay = window.setTimeout(() => {
                    let search = self.filterInput;
                    let tree = $("#participantTreeList").fancytree("getTree");

                    if (search.trim() === "") {
                        tree.clearFilter();
                    } else {
                        tree.filterNodes(search);
                    }
                }, 600);
            },
            removeImage() {
                this.images.splice(this.images.length - 1, 1);
                this.$refs['imageInput-' + (this.activeInput - 1)][0].value = "";
                this.activeInput--;
            }
        },
        components: {
            TimeLineItemLoader
        },
        mounted() {
            materialadmin.App._initInk();
            materialadmin.AppVendor._initTooltips();
        }
    };
</script>

<template>
    <div class="card">
        <div class="card-head card-head-sm style-default-light">
            <button
                    class="btn btn-xs btn-primary publish-post-action-button"
                    :disabled="pending"
                    @click.prevent="setPhotosSectionVisibility"
            >
                <i class="md md-photo"></i>
                Fotoğraf
            </button>
        </div>
        <div class="card-body">
            <form class="form">
                <textarea
                        id="publish-post"
                        class="form-control"
                        rows="4"
                        placeholder="Yazı, resim veya bir bağlantı paylaş..."
                        maxlength="1000"
                        :disabled="pending"
                        v-model.trim="content"
                >{{content}}
                </textarea>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="panel-group" id="accordion">
                            <div class="card panel">
                                <div class="card-head card-head-xs cursor-pointer collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#accordion-settings" aria-expanded="false">
                                    <header>Seçenekler</header>
                                    <div class="tools">
                                        <a class="btn btn-icon-toggle"><i class="fa fa-angle-down"></i></a>
                                    </div>
                                </div>
                                <div id="accordion-settings" class="collapse" aria-expanded="false">
                                    <div class="card-body" style="padding-bottom: 0 !important;">
                                        <div class="text-bold">Gönderi ayarları: </div>
                                        <div class="col-xs-12 col-md-4">
                                            <label class="checkbox-inline checkbox-styled checkbox-primary">
                                                <input type="checkbox" checked :disabled="pending" v-model="canLeaveComment"><span>Yorumlara açık</span>
                                            </label>
                                        </div>
                                        <div class="col-xs-12 col-md-8">
                                            <div class="pull-right">
                                                <label class="radio-inline radio-styled radio-primary">
                                                    <input type="radio" v-model="participantSelection" :disabled="pending" value="easy-selection"><span>Kolay seçim</span>
                                                </label>
                                                <label class="radio-inline radio-styled radio-primary" v-if="moduleId == 0">
                                                    <input type="radio" v-model="participantSelection" :disabled="pending" value="detailed-selection" @change="getParticipantList"><span>Detaylı seçim</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body" v-show="participantSelection === 'easy-selection'">
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-6">
                                                <div class="text-bold">Şunlarla paylaş: </div>
                                                <div>
                                                    <label class="checkbox-inline checkbox-styled checkbox-primary">
                                                        <input type="checkbox" checked :disabled="pending" v-model="student"><span>Öğrenci</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label class="checkbox-inline checkbox-styled checkbox-primary">
                                                        <input type="checkbox" checked :disabled="pending" v-model="parent"><span>Veli</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label class="checkbox-inline checkbox-styled checkbox-primary">
                                                        <input type="checkbox" checked :disabled="pending" v-model="teacher"><span>Öğretmen</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label class="checkbox-inline checkbox-styled checkbox-primary">
                                                        <input type="checkbox" checked :disabled="pending" v-model="systemManager"><span>S. Yöneticisi</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-6" v-show="student || parent">
                                                <div class="text-bold">Sınıf seviyesi:</div>
                                                <div>
                                                    <select class="form-control" v-model="classLevel">
                                                        <option v-for="item in classLevels" :value="item.id" :key="item.id">{{ item.description }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body" v-show="participantSelection === 'detailed-selection'">
                                        <input type="text" id="participantTreeListFilter" class="form-control" placeholder="Ara..." v-model="filterInput" @keyup="filterParticipantTreeList">
                                        <div id="participantTreeList" style="max-height: 300px; overflow-y: auto;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" v-show="addImagesSectionVisible">
                        <input
                                type="file"
                                :ref="'imageInput-' + (index - 1)"
                                :id="'imageInput-' + (index - 1)"
                                accept="image/*"
                                :disabled="maxImages < 1 || pending"
                                v-for="index in maxImages"
                                :key="index - 1"
                                @change="loadImage"
                        >
                        <div class="photos-container">
                            <button
                                    class="btn btn-raised btn-default-light card-hover photo-container photo"
                                    :style="{ 'background-image': 'url(' + image.src + ')' }"
                                    v-for="(image, key) in images"
                                    @click.prevent
                            >
                                <button
                                        class="btn btn-xs btn-floating-action btn-danger ink-reaction stick-top-right image-remove-button"
                                        :disabled="pending"
                                        v-if="key === (activeInput - 1)"
                                        @click.prevent="removeImage()"
                                >
                                    <i class="md md-delete"></i>
                                </button>
                            </button>
                            <button
                                    class="btn btn-raised btn-default-light card-hover photo-container"
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    data-original-title="Fotoğraf ekle"
                                    :disabled="pending"
                                    v-if="maxImages > images.length"
                                    @click.prevent="openImageInput"
                            >
                                <i class="md md-add-to-photos add-image-icon"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-head card-head-sm style-default-light" id="publish-post-bottom-action-section">
            <div id="publish-button-container">
                <button class="btn btn-primary ink-reaction" id="publish-button" :disabled="pending || (!student && !parent && !teacher && !systemManager && participantSelection === 'easy-selection')" @click.prevent="sharePost">Paylaş</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
    textarea {
        padding: 5px;
        border-radius: 5px;
        background: #fff;
        resize: none;
        line-height: 20px;
        font-size: 20px;
    }

    textarea:focus {
        border-color: transparent;
    }

    button {
        margin: 5px 0 0 0;
    }

    input[type='file'] {
        display: none;
    }

    .btn {
        text-transform: capitalize;
    }

    .panel-group {
        margin-bottom: 10px;
    }

    .panel-group .card.expanded {
        margin-bottom: 0;
    }

    .photos-container {
        margin: 14px 0 5px 0;
    }

    .photo-container {
        position: relative;
        width: 100px;
        height: 100px;
        margin-right: 5px;
        line-height: 90px;
    }

    .photo-container .add-image-icon {
        font-size: 45px;
    }

    .photo-container.photo {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .image-remove-button {
        margin-top: -5px;
        margin-right: -5px;
    }

    #publish-button-container {
        float: right;
    }

    #publish-button {
        width: 150px;
        margin: 10px;
    }

    .publish-post-action-button {
        height: 48px;
        margin: 0;
    }

    #publish-post-bottom-action-section {
        min-height: 60px;
        line-height: 60px;
        margin: 0 auto;
    }
</style>
