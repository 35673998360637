<script>
    export default {
        name: 'open-graph-content',
        props: {
            url: { type: String, required: true },
            og: { type: Object, required: true }
        }
    }
</script>

<template>
    <div class="og-container og-youtube-video" v-if="og.YouTube">
        <iframe
                frameborder="0"
                :src="og.embedSource"
                allowfullscreen
        ></iframe>
    </div>
    <a
            class="og-anchor"
            target="_blank"
            :href="url"
            v-else>
        <div class="og-container og-article">
            <div class="og-image" v-if="og.image || og['image:url']">
                <img
                        :src="og.image || og['image:url']"
                        :alt="og.title">
            </div>
            <div class="og-content">
                <div class="og-title">{{og.title}}</div>
                <div class="og-description">{{og.description}}</div>
            </div>
        </div>
    </a>
</template>

<style scoped>
    iframe {
        width: 100%;
        height: 260px;
    }

    .og-container {
        padding: 5px;
    }

    .og-article {
        border: 1px #bab9b6 solid;
        border-radius: 5px;
    }

    .og-image {
        height: 250px;
    }

    .og-title {
        max-height: 1.8em;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .og-description {
        max-height: 3.6em;
        overflow: hidden;
    }

    .og-anchor:hover, .og-anchor:active {
        text-decoration: none;
        color: #616161;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>