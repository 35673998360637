<script>
    export default {
        name: 'deleted-post-content'
    }
</script>

<template>
    <div class="card style-default-bright margin-bottom-xl">
        <div class="card-body">
            <strong>Bu paylaşım kaldırılmış.</strong>
        </div>
    </div>
</template>

<style scoped>
    .card-body {
        padding: 10px;
        text-align: center;
    }
</style>