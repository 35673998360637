<script>
    import TimeLineItem from './TimeLineItem.vue';
    import _ from 'lodash';
    import VueImg from 'v-img';
    import PublishPost from './PublishPost.vue';
    import TimeLineItemLoader from './TimeLineItemLoader.vue';
    import Embed from "v-video-embed";

    Vue.use(VueImg, { altAsTitle: true });
    Vue.use(Embed);

    export default {
        name: 'time-line',
        props: {
            loadPostPerRequest: {type: Number, required: true},
            loadMoreFeature: {type: Boolean, required: true},
            moduleId: {type: Number, required: true},
            referenceId: {type: Number, required: true},
            userTypeDir: {type: String, required: true},
            loadOnCreate: {type: Boolean, required: false, default: false},
            onlyShowOwnStudents: {type: Boolean, required: false, default: false}
        },
        data() {
            return {
                items: [],
                isLoading: true,
                userType: 0,
                moduleId: 0,
                referenceId: 0,
                ownProfilePhoto: '',
                ownNameSurname: '',
                permissions: {
                    publishPost: {
                        allowed: []
                    }
                },
                publishingLoader: false,
                showMoreStories: true,
                loadMoreStoriesButtonIcon: "md md-arrow-drop-down",
                lastLoadedPostId: 2147483647,
            }
        },
        methods: {
            loadTimeLineStories() {
                callAjax(null,{
                    url: `obsapi/timeline/getPosts?moduleId=${this.moduleId}&referenceId=${this.referenceId}&lastLoadedPostId=${this.lastLoadedPostId}&loadPostPerRequest=${this.loadPostPerRequest}&loadType=${(this.lastLoadedPostId === 2147483647 ? 'firstLoad' : 'older')}`,
                    method: 'GET',
                    context: this
                },function (obj, scope) {
                    let receivingPosts = _.orderBy(obj.data, ['pinned', 'id'], ['asc', 'desc']);

                    scope.items.push(...receivingPosts);

                    if(receivingPosts.length > 0) {
                        scope.lastLoadedPostId = receivingPosts[receivingPosts.length - 1].id;
                    } else {
                        scope.showMoreStories = false;
                    }

                    scope.isLoading = false;
                    scope.isLoadingMoreStories = false;
                    scope.loadMoreStoriesButtonIcon = "md md-arrow-drop-down";
                },function (scope) {
                    scope.isLoadingMoreStories = true;
                    scope.loadMoreStoriesButtonIcon = "fa fa-spinner fa-pulse";
                });
            },
            addPostToTimeLine(item) {
                this.items.unshift(item);
            },
            addLoaderToTimeLine(add) {
                this.publishingLoader = add;
            },
            loadMoreStories() {
                this.loadTimeLineStories();
            },
            deletePostInTimeLine(id) {
                const postItemPosition = this.items.map((x) => {
                    return x.id;
                }).indexOf(id);

                this.items.splice(postItemPosition, 1);
            },
            pinPostInTimeLine(id) {
                const postItemPosition = this.items.map((x) => {
                    return x.id;
                }).indexOf(id);

                this.items.forEach(key => {
                    if (postItemPosition !== key) {
                        this.items.pinned = false;
                    }
                });
            },
            initTooltips() {
                materialadmin.AppVendor._initTooltips();
            },
            getConfig() {
                callAjax(null, {
                    url: `obsapi/timeline/getConfig?moduleId=${this.moduleId}`,
                    method: 'GET',
                    context: this
                }, function (obj, scope) {
                    //scope.userTypeDir = obj.config.user.directory;
                    scope.userType = obj.config.user.type;
                    if (obj.config.user.onlyShowOwnStudents !== undefined) {
                        scope.onlyShowOwnStudents = obj.config.user.onlyShowOwnStudents;
                    }
                    //scope.moduleId = obj.config.moduleId;
                    //scope.referenceId = obj.config.referenceId;
                    scope.classLevels = obj.classLevels;
                    scope.permissions = obj.config.permissions;
                    scope.ownProfilePhoto = obj.config.user.profile;
                    scope.ownNameSurname = obj.config.user.nameSurname;
                    scope.loadTimeLineStories();
                });
            },
        },
        components: {
            TimeLineItem,
            PublishPost,
            TimeLineItemLoader
        },
        updated: function () {
            this.$nextTick(function () {
                this.initTooltips();
            })
        },
        created() {
            if (this.loadOnCreate) {
                this.getConfig();
            } else {
                const self = this
                Router.onPageEnter.push(function (route) {
                    self.getConfig();
                });
            }
        }
    }
</script>

<template>
    <div>
        <publish-post
            v-on:addPostToTimeLine="addPostToTimeLine($event)"
            v-on:addLoaderToTimeLine="addLoaderToTimeLine($event)"
            :userTypeDir="userTypeDir"
            :moduleId="moduleId"
            :referenceId="referenceId"
            :classLevels="classLevels"
            :userType="userType"
            :onlyShowOwnStudents="onlyShowOwnStudents"
            v-if="permissions.publishPost.allowed.indexOf(userType) !== -1"
        />
        <time-line-item-loader v-if="publishingLoader" />
        <template v-if="isLoading">
            <time-line-item-loader />
            <time-line-item-loader />
            <time-line-item-loader />
        </template>
        <div v-else>
            <div
                    class="no-item-container"
                    v-if="items.length < 1"
            >
                <div class="no-item-title">
                    <span><i class="fa fa-frown-o"></i> Görünen o ki, henüz hiç kimse bir şey paylaşmamış...</span>
                </div>
            </div>
            <template v-else>
                <time-line-item
                    v-for="item in items"
                    :key="item.id"
                    :id="item.id"
                    :content="item.content"
                    :canLeaveComment="item.canLeaveComment"
                    :nameSurname="item.nameSurname"
                    :profilePhoto="item.profilePhoto"
                    :ownProfilePhoto="ownProfilePhoto"
                    :ownNameSurname="ownNameSurname"
                    :ownPost="item.ownPost"
                    :realDate="item.realDate"
                    :realDateFormatted="item.realDateFormatted"
                    :likes="item.likes"
                    :comments="item.comments"
                    :liked="item.liked"
                    :pinned="item.pinned"
                    :hashList="item.hashList"
                    :og="item.og"
                    :referenceContent="item.referenceContent"
                    :url="item.url"
                    :userTypeDir="userTypeDir"
                    :moduleId="item.moduleId"
                    :referenceId="item.referenceId"
                    v-on:deletePostInTimeLine="deletePostInTimeLine($event)"
                    v-on:pinPostInTimeLine="pinPostInTimeLine($event)"
                    :userType="userType"
                />
                <button
                        id="load-more-button"
                        class="btn btn-block ink-reaction btn-default-bright"
                        :disabled="isLoadingMoreStories"
                        v-if="showMoreStories"
                        @click="loadMoreStories"
                >
                    Daha fazla yükle <i :class="loadMoreStoriesButtonIcon"></i>
                </button>
            </template>
        </div>
    </div>
</template>

<style scoped>
    h4 {
        color: #0aa89e;
    }

    .no-item-container {
        display: table;
        height: 150px;
        width: 100%;
        margin-bottom: 15px;
        text-align: center;
        border: 3px #d6d6d7 solid;
        background: #f2f2f3;
        border-radius: 5px;
    }

    .no-item-title {
        display: table-cell;
        font-size: 16px;
        font-weight: 200;
        vertical-align: middle;
    }

    #load-more-button {
        text-transform: none;
    }

    .btn {
        text-transform: capitalize;
    }
</style>
