<script>
    export default {
        name: 'comment-list-item',
        props: {
            id: { type: Number, required: true },
            profilePhoto: { type: String, required: true },
            nameSurname: { type: String, required: true },
            ownComment: { type: Boolean, required: true },
            comment: { type: String, required: true },
            userTypeDir: { type: String, required: true },
            moduleId: { type: Number, required: true },
            referenceId: { type: Number, required: true }
        },
        methods: {
            escapeHTML(text) {
                return text
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;");
            },
            URLTextToAnchor(text) {
                const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                return text.replace(exp,"<a target='_blank' href='$1'>$1</a>");
            },
            deleteComment() {
                if(this.ownComment) {
                    if(confirm('Bu yorumunuzu silmek istediğinizden emin misiniz?')) {
                        callAjax(null,{
                            url: `obsapi/timeline/deleteComment?commentId=${this.id}`,
                            method: 'GET',
                            context: this
                        },function (obj, scope) {
                            scope.$emit('deleteCommentInCommentList', scope.id);
                        });
                    }
                }
            }
        }
    };
</script>

<template>
    <div class="comment-container">
        <img :src="profilePhoto" :alt="nameSurname" class="img-circle img-responsive pull-left width-1">
        <div class="comment-section"><span class="text-primary text-bol hover-underline comment-name-surname">{{nameSurname}}</span>
            <span class="comment-text" v-html="URLTextToAnchor(escapeHTML(comment))"></span>
        </div>
        <button
                class="btn btn-xs btn-flat delete-button stick-top-right cursor-pointer"
                v-if="ownComment"
                @click="deleteComment"
        >
            <i class="md md-close"></i>
        </button>
    </div>
</template>

<style scoped>
    .comment-container {
        position: relative;
        display: block;
        margin-bottom: 16px;
    }

    img {
        width: 30px;
        height: 30px;
    }

    .delete-button i {
        font-size: 12px;
    }

    .comment-section {
        padding: 4px 15px 0 36px;
        line-height: 20px;
    }

    .comment-name-surname {
        font-size: 13px;
        font-weight: 500;
    }

    .comment-text {
        margin-left: 5px;
    }

    .comment-text a {
        color: darkorange !important;
    }
</style>
