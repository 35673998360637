<script>
    export default {
        name: 'leave-comment',
        props: {
            show: { type: Boolean, default: false },
            ownProfilePhoto: { type: String, required: true },
            ownNameSurname: { type: String, required: true }
        },
        data() {
            return {
                mShow: this.show
            }
        },
        methods: {
            emitLeaveComment() {
                const comment = this.$refs.commentInput.innerText.trim();

                if(comment === "") {

                } else if(comment.length > 255) {
                    alert('Yorumunuz 255 karakteri geçemez.');
                } else if(comment !== "") {
                    this.$emit('leaveComment', comment);
                    this.$refs.commentInput.innerText = '';
                }
            }
        },
        mounted() {
            this.$refs.commentInput.focus();
        }
    };
</script>

<template v-if="mShow">
    <div class="card-actionbar-row">
        <img
                :src="ownProfilePhoto.indexOf('downloadFileByToken') === -1 ? ownProfilePhoto : 'obsapi/' + ownProfilePhoto"
                :alt="ownNameSurname"
                class="img-circle img-responsive pull-left width-1" >
        <div
                class="text-selectable"
                contenteditable="true"
                placeholder="Yorum yaz..."
                ref="commentInput"
                @keypress.enter.prevent="emitLeaveComment"
        ></div>
        <button
                class="btn btn-xs btn-primary send-comment"
                @click="emitLeaveComment"
        >
            Gönder
        </button>
    </div>
</template>

<style scoped>
    .card-actionbar-row {
        text-align: left;
        background: #f6f7f9;
    }

    [contenteditable=true]:empty:before {
        content: attr(placeholder);
        display: block;
        color: #90949c;
    }

    [contenteditable=true] {
        display: inline-block;
        width: calc(100% - 90px);
        margin-left: 5px;
        padding: 2px 12px;
        border: 1px solid #dddfe2;
        border-radius: 22px;
        font-size: 13px;
        cursor: text;
        outline: 0;
        -webkit-user-modify: read-write-plaintext-only;
    }

    .send-comment {
        height: 31px;
        border-radius: 2px;
    }

    .btn {
        text-transform: capitalize;
    }

    img {
        width: 30px;
        height: 30px;
    }
</style>
