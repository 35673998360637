<script>
    export default {
        name: 'club-activity-container',
        props: {
            id: { type: Number, required: true },
            title: { type: String, required: true },
            description: { type: String, required: true },
            hash: { type: Boolean, required: false },
            startDate: { type: String, required: true },
            endDate: { type: String, required: true },
            realDateFormatted: { type: String, required: true }
        }
    }
</script>

<template>
    <div class="card style-default-bright margin-bottom-xl">
        <div class="card-body">
            <div class="text-selectable">
                <dl class="dl-horizontal">
                    <dt>Aktivite</dt>
                    <dd>{{title}}</dd>
                    <dt>Detay</dt>
                    <dd>{{description}}</dd>
                    <dt>Başlangıç zamanı</dt>
                    <dd>{{startDate}}</dd>
                    <dt>Bitiş zamanı</dt>
                    <dd>{{endDate}}</dd>
                </dl>
            </div>
            <div
                    class="post-image cursor-pointer"
                    v-if="hash"
            >
                <img
                        class="object-fit"
                        v-img="{ group: 'group-' + id }"
                        :src="'download.php?file=' + hash + '&view '"
                        :alt="realDateFormatted"
                >
            </div>
        </div>
    </div>
</template>

<style scoped>
    .dl-horizontal {
        margin-bottom: 10px;
    }

    .post-image {
        height: 250px;
    }

    .object-fit {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>